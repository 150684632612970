import React, { useEffect } from 'react';
import FullPageCarousel from '../Components/Carousel/FullPageCarousel';
import ImageCard from '../Components/ImageCard/ImageCard';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './PageCss/HomePage.css';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import CenterStage from '../Components/CenterStage/CenterStage';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook


import imgHose from '../assets/hose.jpg';
import imgCarton from '../assets/Hansa-Flex_Kartons_Shop.png';

import Promoter from '../Components/Promoter/Promoter';
import FeatureCardCarousel from '../Components/FeaturesCardCarousal/FeatureCardCarousal'
import AOS from 'aos';
import 'aos/dist/aos.css';
import circleCheck from '../assets/check-circle.svg'
import imgIndustriesServedBelgium from '../../src/assets/IndustriesServedBelgium.png'
import Horizontal from '../Components/Horizontal/Horizontal';
import imgHome_BelgiumPipes from '../assets/Home_BelgiumPipes.jpg';
import imgHomeBelgium_driveandcontroltechnology from '../assets/HomeBelgium_driveandcontroltechnology.jpg';
import imgHomeBelgium_INDHOSES from '../assets/HomeBelgium_INDHOSES.jpg';
import imgHomeBelgium_Adaptar from '../assets/HomeBelgium_Adaptar.jpg';
import imgHomeBelgium_Scan from '../assets/HomeBelgium_Scan.jpg';
import imgHomeBelgium_Cylinder from '../assets/HomeBelgium_Cylinder.jpg'
import imgHomeBelgium_Xcode from '../assets/HomeBelgium_Xcode.jpg';
import imgHomeBelgium_Indinspection from '../assets/HomeBelgium_Indinspection.jpg';
import imgHomeBelgium_Cylinderrepair from '../assets/HomeBelgium_Cylinderrepair.jpg';
import imgbestquality from '../assets/bestquality.png'
import imgFlexibleassemblyteam from '../assets/Flexibleassemblyteam.png'
import imgXcode from '../assets/Xcode.png'
import imgWarehouse from '../assets/Warehouse.png'





function HomePage() {

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);


  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 320, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 300, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, []);

  const featuresData = [
    {
      title: "All under one roof",
      description: "Benefit from a complete range of hydraulic hoses and connecting elements for the hydraulics",
      image: imgWarehouse
    },
    {
      title: "Flexible assembly teams",
      description: "Our assembly teams are always ready with a fully equipped workshop trolley.",
      image: imgFlexibleassemblyteam
    },
    {
      title: "X-CODE",
      description: "Reduce unplanned machine downtime to a minimum with the X-CODE.",
      image: imgXcode
    },

    {
      title: "Best quality",
      description: "Certified according to DIN EN ISO 9001: 2015, we stand for the highest quality standards.",
      image: imgbestquality
    },

    // Add more objects for additional FeaturesCard data
  ];

  //promoter content

  const promoterTitle = 'PLACE YOUR ORDER TODAY!';
  const promoterChecklistItems = [
    'The full HANSA‑FLEX product range with more than 80,000 articles.',
    'Category-specific filter parameters enable quick navigation.',
    'Please call us for availability.'
  ];
  const promoterButtonLabels = ['CONTACT US', 'FIND OUT MORE !'];

  const googleReviewsLink = "https://www.google.com/search?q=HANSA-FLEX+Hydraulics+Middle+East+Electromechanical+Works+L.L.C.%0D%0A&sca_esv=5fc86f3f882bb04d&rlz=1C1GCEU_enIN1077IN1077&sxsrf=ADLYWIL4idna669YeckDiMTYuKLOHzGRjg%3A1717400752112&ei=sHRdZqG-BsnV4-EPnvyZmQc&ved=0ahUKEwih1pCu-L6GAxXJ6jgGHR5-JnMQ4dUDCBA&uact=5&oq=HANSA-FLEX+Hydraulics+Middle+East+Electromechanical+Works+L.L.C.%0D%0A&gs_lp=Egxnd3Mtd2l6LXNlcnAiQUhBTlNBLUZMRVggSHlkcmF1bGljcyBNaWRkbGUgRWFzdCBFbGVjdHJvbWVjaGFuaWNhbCBXb3JrcyBMLkwuQy4KMgcQIxiwAxgnMgcQIxiwAxgnMgcQIxiwAxgnMgoQABiwAxjWBBhHMgoQABiwAxjWBBhHMgoQABiwAxjWBBhHMgoQABiwAxjWBBhHMgoQABiwAxjWBBhHMgoQABiwAxjWBBhHMgoQABiwAxjWBBhHSKwNUIgIWIgIcAN4AZABAJgBAKABAKoBALgBA8gBAPgBAvgBAZgCA6ACFZgDAIgGAZAGCpIHATOgBwA&sclient=gws-wiz-serp#lrd=0x3e5f6bdae190b3cf:0x98b7ff27fa3849e,1";

  return (
    <div>
      <FullPageCarousel />

      <SectionHeader
        title="Our Products"
      />
      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgHose} learnMoreLink="/hydraulic-hoses" title="HANSA-FLEX PROVIDES PREMIUM QUALITY HOSES" desc="The HANSA‑FLEX range includes hoses for hydraulics and pneumatics as well as for the transport of chemicals, food, air, water and abrasive media. In addition to standard hoses, we also supply suction and positive pressure hoses, suitable accessories and preassembled hose lines manufactured to your individual requirements. As a full-service provider, we also support our customers with installation, maintenance and inspection." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgHome_BelgiumPipes} learnMoreLink="/pipe-fitting" title="PIPES FITTINGS" desc="Transitions between components in hydraulic systems are always a point for careful consideration. Pipe fittings from HANSA‑FLEX are standardised in accordance with DIN EN ISO 8434-1 and DIN 2353...." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgHomeBelgium_Adaptar} learnMoreLink="/adapters" title="ADAPTERS" desc="Adapters create a safe and leak-free connection between components with differing thread types.HANSA‑FLEX offers an extensive portfolio of hydraulic adapters, such as threaded or transition adapters in all common connection....." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgHomeBelgium_INDHOSES} learnMoreLink="/Industrial-hoses" title="SYSTEM PARTNER FOR INDUSTRIAL HOSES" desc="Industrial hoses find application where the medium is not used to transmit energy. Industrial hoses transport a wide variety of media, from water and air to chemicals, fuels, and gases, in almost every industry sector. In its hose specialist workshop, HANSA-FLEX meets customer requirements with the highest quality standards and shortest delivery times. In the HANSA-FLEX online shop, you will find a wide range of industrial hoses." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgHomeBelgium_Cylinder} learnMoreLink="/cylinder" title="CYLINDERS" desc="Hydraulic cylinders perform extremely heavy work. As the most important drive elements in hydraulics, they convert the pressure energy of the hydraulic fluid into mechanical energy..." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgHomeBelgium_driveandcontroltechnology} learnMoreLink="/drive-and-control-tech" title="DRIVE AND CONTROL TECHNOLOGY" desc="Hydraulic components fulfil the highest quality standards at HANSA‑FLEX, where quality management has DIN ISO 9001 certification. Availability is ensured by our warehouse, which stocks over 4,500 hydraulic components." />
          </Col>

        </Row>
      </Container>

      <SectionHeader
        title="Our Services"
      />
      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgHomeBelgium_Scan} learnMoreLink="/Scannersolutions" title="EASY ORDER SCAN" desc="Yes, we scan! Scanner solutions from HANSA‑FLEX enable you to determine your goods requirement for fluid technology in the warehouse or on the production line more quickly and order conveniently using the app integrated into the scanner. " />
          </Col>
          <Col sm={12} md={6} lg={8} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgHomeBelgium_Xcode} learnMoreLink="/x-code-manager" title="X-CODE MANAGER" desc="X-CODE, the coding and identification system from HANSA‑FLEX, has been proven in practice millions of times. Each individual hose line is uniquely identified based on its X-CODE and a suitable replacement part can be obtained in the shortest possible time anywhere in the world. X-CODE gives every hose line a digital profile containing all the relevant information..." />
          </Col>
          <Col sm={12} md={6} lg={8} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgHomeBelgium_Indinspection} learnMoreLink="/industrial-hose-inspection" title="HYDRAULIC INDUSTRIAL SERVICE" desc="HANSA‑FLEX inspects your industrial hose lines in accordance with the German Industrial Safety Act (BetrSichV). We perform inspections as an on-site service from our mobile inspection service vehicles or in our centres of excellence for hose technology [KD1] [MUM2]" />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgHomeBelgium_Cylinderrepair} learnMoreLink="/hydraulic-cyclinder-repair" title="CYLINDER REPAIR" desc="Hydraulic cylinders are subject to extreme stresses due to their continual extending and retracting. The result is wear, which puts the whole working process at risk of failure. Overhauling a cylinder is a good alternative to buying a new one." />
          </Col>

        </Row>
      </Container>



      {/* <div>
        <SectionHeader
          title="Our Services"
        /> */}
      {/* 
        <Container className="custom-container">
          <Row> */}




      {/* </Row>
        </Container>


      </div> */}


      {/* <div>
        <SectionHeader
          title="Explore our units!"
          paragraph="We have our units in Pune, which provide extensive range of products!"
        />
        <ShowcaseCarousel carouselItems={carouselItems} descDisplay={isMobile ? 'none' : 'block'} />
      </div>
      <br /><br /><br /> */}

      <div className='div-gray center-all-items'>
        <SectionHeader
          title="Premium Hydraulics & Pneumatics Solutions"
          subtitle="Select from more than 80,000 articles and always find the right product for your needs. We stock everything from A-Z."
        />
        <FeatureCardCarousel featuresData={featuresData} />
      </div>


      <div>
        {isLaptop && (
          <Promoter
            title={promoterTitle}
            checklistItems={promoterChecklistItems}
            btnLabel1={"Explore more products!"}
            btnLabel1Link={"/hose-line-config"}
            btnLabel2={"Order now!"}
            btnLabel2Link={"https://shop.hansa-flex.be/nl_NL/"}
            imageUrl={imgCarton}
          />
          // TODO: contact us button
        )}
      </div>


      {/* <div className="text-center div-gray">
        <SectionHeader
          title={"Check out our reviews on google!"}
          paragraph={"Curious about what others have to say about us? Take a moment to explore our Google reviews and discover why our customers rave about their experiences with us. "} />
        <br />
        <Button variant="primary" href={googleReviewsLink} target="_blank" className="btn-google" style={{ boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)' }}>View our reviews on Google</Button>

        <br /><br /><br />
      </div> */}

      {/* <div>
        <SectionHeader
          title="News and Events" />
        <Container className="custom-container">
          <Row>
            <Col sm={12} md={6} lg={8} data-aos="fade-up" ><ImageCard image={imgIree} learnMoreLink={'/iree'} title="HANSA-FLEX at IREE" desc={"HANSA-FLEX India is thrilled to have participated in the 15th edition of the International Railway Equipment Exhibition (IREE), which took place from October 12 to 14, 2023, at Pragati Maidan, New Delhi. IREE stands as Asia's largest platform dedicated to the Rail Transportation Sector, and this event was organized by the Confederation of Indian Industry (CII) in collaboration with the Ministry of Railways, Government of India."} /></Col>
            <Col sm={12} md={6} lg={4} data-aos="fade-up"><ImageCard image={imgBauma} learnMoreLink={'/bauma'} title="Bauma Conexpo India 2023 " desc="The 6th edition of international trade fair Bauma Conexpo India 2023, construction machinery event was held in Greater Noida, from Jan 31st -3rd Feb, 2023. " /></Col>
            <Col sm={12} md={6} lg={4} data-aos="fade-up"><ImageCard image={imgTree} learnMoreLink={'/tree-plantation'} title="Tree Plantation on Environment Day" desc="HANSA-FLEX India Pvt Ltd proudly celebrates Environment Day by organizing a tree plantation event, acknowledging the importance of environmental conservation and sustainability. The event is scheduled to take place on the 5th of June 2023, from 9:00 am to 11:00 am" /></Col>
            <Col sm={12} md={6} lg={4} data-aos="fade-up"><ImageCard image={imgGarry} learnMoreLink={'/product-training'} title="Product training session taken by Gary Howes" desc="Join us for an extensive training session on Hose Assembly, Fittings, and Welding Products presented by Gary Howes. Taking place from the 4th to the 14th of July 2023 at HANSA-FLEX (IFP) INDIA PVT LTD, this comprehensive training program will cover a wide range of topics essential for professionals in the industry." /></Col>
            <Col sm={12} md={6} lg={4} data-aos="fade-up"><ImageCard image={imgGoetheVisit} learnMoreLink={'/goethe-event'} title="Empowering Students: Insights into Career Opportunities and Language Skills" desc="Join us for an extensive training session on Hose Assembly, Fittings, and Welding Products presented by Gary Howes. Taking place from the 4th to the 14th of July 2023 at HANSA-FLEX (IFP) INDIA PVT LTD, this comprehensive training program will cover a wide range of topics essential for professionals in the industry." /></Col>
          </Row>
        </Container>
      </div> */}


      <div>
        <SectionHeader title={"Industries Served"} />
        <div className="home-imageContainer">
          {isMobile ? <>
            <img src={imgIndustriesServedBelgium} alt="Description of the image" className='home-img' />

          </> : <>
            <img src={imgIndustriesServedBelgium} alt="Description of the image" className='home-img' />

          </>}
        </div>
      </div>
      


      {/* <div className="text-center div-white">
        <SectionHeader
          title={"Check out our reviews on google!"}
          paragraph={"Curious about what others have to say about us? Take a moment to explore our Google reviews and discover why our customers rave about their experiences with us. "} />
        <br />
        <Button variant="primary" href={googleReviewsLink} target="_blank" className="btn-google" style={{ boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)' }}>View our reviews on Google</Button>
      </div> */}

      <div>
        <Horizontal />
      </div>
    </div>

  );
}

export default HomePage;
