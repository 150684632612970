import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'

import imgStage from '../../assets/Products/Catalogues/stage.jpg'

const Catalogues = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"PRODUCTS AT A GLANCE"}
                    paragraph={"All HANSA‑FLEX catalogues as PDF in German and English can be found on this page. You are also welcome to use our online shop as a digital listing of all products."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"THE HANSA‑FLEX PRODUCT CATALOGUES"}
                    subtitle={"All catalogues available as PDF in German and English"}
                />
                {/* TODO: steps */}
            </div>

        </div>
    )
}


export default Catalogues