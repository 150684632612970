import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { MdLocationOn, MdEmail, MdPhone } from 'react-icons/md';
import { Link } from 'react-router-dom';

const ContactDetails = ({ companyInfo }) => {
    return (
        <div style={{ backgroundColor: '#30557d', padding: '20px', color: 'white', paddingLeft: '10%' }}>
            <Row>
                <Col xs={12} md={6}>
                    <h2>Contact Details:</h2>
                    <div>
                        <p>
                            <a
                                href={companyInfo.googleMapsUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'white', textDecoration: 'none' }}
                            >
                                <MdLocationOn /> {companyInfo.name} <br />
                                {companyInfo.address}
                            </a>
                        </p>
                        <p>
                            <MdEmail /> Email: <a href={`mailto:${companyInfo.email}`} style={{ color: 'white' }}>{companyInfo.email}</a>
                        </p>
                        <p>
                            <MdPhone /> Telephone: <a href={`tel:${companyInfo.phone}`} style={{ color: 'white', textDecoration: 'none' }}>{companyInfo.phone}</a>
                        </p>
                    </div>
                    <Button as={Link} to="/contact-us" variant="light" style={{ marginTop: '20px' }}>
                        Contact Us
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default ContactDetails;
