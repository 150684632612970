import React, { useEffect } from 'react';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgBelgium_Contactpage from '../../assets/ContactLocation/BelgiumStage.png'
import imgBelgium_Drogon from "../../assets/ContactLocation/Drongen.png"
import imgBelgium_Hasselt from "../../assets/ContactLocation/Hasselt.png"
import imgBelgium_Ant from "../../assets/ContactLocation/Antwerpen.png"
import imgBelgium_Genk from "../../assets/ContactLocation/LIS.png"
import imgBelgium_Campinaire from "../../assets/ContactLocation/Campineire.png"
import imgBelgium_Avenue from "../../assets/ContactLocation/Avenue.png"



const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const units = [
        {
            name: 'HANSA-FLEX Hydraulics NV',
            location: 'HANSA-FLEX Hydraulics NV, Industriepark-Drongen 12, 9031 Gent, Belgium',
            telephone: '+32 9 282 36 15',
            mail: 'gen@hansa-flex.be',
            image: imgBelgium_Drogon
        },


        {
            name: 'HANSA-FLEX Hydraulics NV',
            location: 'HANSA-FLEX Hydraulics NV, Nieuwe steenweg 10, 3500 Hasselt, Belgium',
            telephone: ' +32 11 22 02 02',
            mail: 'has@hansa-flex.be',
            image: imgBelgium_Hasselt
        },

        {
            name: 'HANSA-FLEX Hydraulics NV',
            location: 'HANSA-FLEX Hydraulics NV, Göteborgweg 2, 2030 Antwerpen, Belgium',
            telephone: '+32 3 542 10 10',
            mail: 'ant@hansa-flex.be',
            image: imgBelgium_Ant
        },

        {
            name: 'HANSA-FLEX Hydraulics SA',
            location: 'HANSA-FLEX Hydraulics SA, Route du vieux Campinaire 48, 6220 Fleurus, Belgium',
            telephone: '+32 71 40 46 97',
            mail: 'cha@hansa-flex.be',
            image: imgBelgium_Campinaire
        },

        {
            name: 'HANSA-FLEX Hydraulics SA',
            location: 'HANSA-FLEX Hydraulics SA, 1ère Avenue 245, 4040 Herstal, Belgium',
            telephone: '+32 4 278 76 00',
            mail: 'her@hansa-flex.be',
            image: imgBelgium_Avenue
        },
        {
            name: 'LIS- Hydraulics BV',
            location: 'LIS- Hydraulics BV, Eikelaarstraat 19, 3600 Genk, Belgium',
            telephone: '+32 89 35 97 16',
            image: imgBelgium_Genk
        },

    ];

    return (
        <div className="">
            <div>
                <CenterStage
                    imageSrc={imgBelgium_Contactpage}
                    title={"Get in touch with HANSA-FLEX Belgium"}
                    paragraph={"Dive into a world of hydraulic expertise and personalised service. Contact us today and see how HANSA-FLEX Belgium can take your hydraulic operations to new heights."} />
            </div>
            <div className=''>
                <ContactUsCard units={units} />
            </div>
        </div>
    );
};

export default ContactUs;
