import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import image1 from '../../assets/home2.jpg';
import stage1 from '../../assets/stage-1.jpg';
import stage3 from '../../assets/stage3.jpg';
import stage5 from '../../assets/stage5.jpg';
import './FullPageCarousel.css'; // Import CSS file
import { Link } from 'react-router-dom';
import { BiAlignJustify } from 'react-icons/bi';

function FullPageCarousel() {

  const imageContainerStyle = {
    position: 'relative', // Make the container relative for absolute positioning of overlay
    maxHeight: '100%', // Set max height to auto
    maxWidth: '100%', // Set max width to auto
    overflow: 'hidden', // Hide overflow to crop image
    margin: '0 auto', // Center the container horizontally
    width: '100vw', // Set width to fill the viewport width
    display: 'flex', // Set display to flex
    justifyContent: 'center', // Center content horizontally
    alignItems: 'center' // Center content vertically
  };

  const imageStyle = {
    width: '100%', // Set width to fill container
    height: 'auto', // Maintain aspect ratio
    objectFit: 'cover' // Crop the image to cover the entire container
  };

  const titleStyle = {
    marginBottom: '50px',
  };

  const descriptionStyle = {
    marginBottom: '30px', // Set bottom margin for description
    marginLeft: '20%', // Set left margin for description
    marginRight: '20%' // Set right margin for description
  };

  const buttonStyle = {
    backgroundColor: 'rgb(212,51,75)', // Button background color
    color: 'white', // Button text color
    padding: '10px 20px', // Button padding
    borderRadius: '5px', // Button border radius
    border: 'none', // Remove button border
    cursor: 'pointer', // Add cursor pointer on hover
    textDecoration: 'none', // Remove underline from button text
    width: '200px', // Adjust button width to fit content

  };

  const buttonWrapperStyle = {
    display:'flex',
    BiAlignJustify:'center',
    justifyContent:'center',
    gap:'10px',
    textAlign: 'center', // Center content horizontally within the wrapper
    marginBottom: '60px', // Set bottom margin for button wrapper
  };

  return (
    <Carousel>
      <Carousel.Item interval={2500} className='main-carousel-item'>
        <div style={imageContainerStyle} >
          <img className='main-carousel-image'
            src={stage1}
            alt="First slide"
            style={imageStyle}
          />
          <div className="image-overlay"> {/* Add the overlay */}
            <h3 className='c-slide-title' style={titleStyle}>HANSA-FLEX stands for System Technology</h3>
            <p className='c-slide-desc' style={descriptionStyle}> HANSA-FLEX Belgium is a proud subsidiary of the HANSA-FLEX AG group, a renowned provider of fluid power solutions globally.   </p>
            <div style={buttonWrapperStyle}> {/* Button wrapper */}
              <Link to="/hoses-and-hose-lines" style={buttonStyle}>Learn More</Link>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item interval={2500} className='main-carousel-item'>
        <div style={imageContainerStyle} >
          <img className='main-carousel-image'
            src={image1}
            alt="Second slide"
            style={imageStyle}
          />
          <div className="image-overlay"> {/* Add the overlay */}
            <h3 className='c-slide-title' style={titleStyle}>Explore our distinguished product line !</h3>
            <p className='c-slide-desc' style={descriptionStyle}>Experience excellence with our distinguished product line! Explore a curated selection of premium goods, meticulously crafted to elevate your lifestyle. From innovative tech gadgets to luxurious lifestyle essentials, each item in our collection embodies quality, sophistication, and functionality. Discover the epitome of excellence today!</p>
            <div style={buttonWrapperStyle}> {/* Button wrapper */}
              <Link to="/hose-line-config" style={buttonStyle}>Discover More</Link>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item interval={2500} className='main-carousel-item'>
        <div style={imageContainerStyle} >
          <img className='main-carousel-image'
            src={stage3}
            alt="Second slide"
            style={imageStyle}
          />
          <div className="image-overlay"> {/* Add the overlay */}
            <h3 className='c-slide-title' style={titleStyle}>We like to hire you !</h3>
            <p className='c-slide-desc' style={descriptionStyle}>At HANSA-FLEX, we share a passion for machinery and engineering. If you’re seeking new challenges and have a drive for keeping things running smoothly, you’ve found the right place. We value open feedback, strong team cohesion, and exciting tasks that make work—whether in the office, warehouse, IT, or workshop—more than just a job. Explore our open vacancies and apply today!</p>
            <div style={buttonWrapperStyle}> {/* Button wrapper */}
              <Link to="/careers" style={buttonStyle}>Discover More</Link>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item interval={2500} className='main-carousel-item'>
        <div style={imageContainerStyle} >
          <img className='main-carousel-image'
            src={stage5}
            alt="Second slide"
            style={imageStyle}
          />
          <div className="image-overlay"> {/* Add the overlay */}
            <h3 className='c-slide-title' style={titleStyle}>L.I.S. Hydraulics boosts HANSA-FLEX!</h3>
            <p className='c-slide-desc' style={descriptionStyle}>We are proud to inform you that L.I.S. Hydraulics has been part of the HANSA-FLEX group since January 1, 2022.</p>
            <div style={buttonWrapperStyle}> {/* Button wrapper */}
              <a href="HF_LIS.jpg" target="_blank" rel="noopener noreferrer" style={buttonStyle}>More Info</a>
              <a href="LISFR.jpg" target="_blank" rel="noopener noreferrer" style={buttonStyle}>Discover more</a>
            </div>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default FullPageCarousel;
